<template>
  <div
    class="v-select"
    :id="data.id"
    tabindex="0"
    @focusout="closeSelect"
    :style="{ 'margin-bottom': errorBag?.length ? '40px' : '' }"
  >
    <!-- <p class="v-select-pre__text" v-if="this.$slots.pre_text">
      <slot name="pre_text"></slot>
    </p> -->

    <div
      class="v-select__value"
      :class="{
        'v-select--error': errorBag.length,
        suggestionFix: data.visible,
      }"
      @click="openSelect"
    >
      <span>
        {{ modelValue == "" ? data?.label : modelValue }}
      </span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{ 'icon-revert': data.visible }"
      >
        <path
          d="M16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504L16.59 8.29504Z"
          fill="black"
          fill-opacity="0.54"
        />
      </svg>
    </div>

    <p class="v-select__error">
      {{ errorBag?.[0] }}
    </p>
    <transition name="fade" mode="out-in">
      <div class="v-select__list" v-show="data.visible">
        <p
          class="v-select__option"
          v-for="(item, index) in items.length ? items : data.options"
          :key="index"
          @click="
            selectOption(item, data), $emit('update:modelValue', item.name)
          "
        >
          {{ item.name }}
        </p>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "v-select",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: { type: String, default: null },
    errorBag: {
      type: Array,
      default: () => [{ name: "Обязательно для заполнения" }],
    },
  },
  methods: {
    openSelect() {
      if (this.data.visible == true) {
        this.closeSelect();
      } else {
        console.log(this.data);
        this.$emit("openSelect", this.data);
      }
    },
    closeSelect() {
      this.$emit("closeSelect", this.data);
    },
    selectOption(option, data) {
      const payload = {
        option: option,
        field: data.id,
      };
      console.log(data);
      this.$emit("selectOption", payload);
      this.closeSelect();
    },
  },
};
</script>
<style lang="scss">
.v-select {
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  background-color: $white;
  transition: 0.3s ease;
  &:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  // .v-select__label

  &__label {
    font-family: "Roboto", sans-serif;
    margin-bottom: 12px;
  }

  // .v-select__value

  &__value {
    font-family: "Roboto", sans-serif;
    height: 44px;
    background-color: $white;
    outline: none;
    border-radius: 10px;
    font-size: 14px;
    line-height: 16px;
    padding: 14px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      transition: 0.3s;
    }
  }

  // .v-select__error

  &__error {
    font-family: "Roboto", sans-serif;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    padding: 0 14px;
    font-size: 12px;
    line-height: 130%;
    color: $accent;
    max-width: 100%;
    white-space: break-spaces;
    margin-top: 4px;
    span,
    a {
      color: inherit;
      border-bottom: 1px solid $accent;
      padding-bottom: 1px;
    }
  }

  // .v-select--error

  &--error {
    border-color: $accent;
  }

  // .v-select__list

  &__list {
    position: absolute;
    font-family: "Roboto", sans-serif;
    top: 100%;
    left: 0;
    background-color: $white;
    border: 1px solid $second-black;
    outline: none;
    width: 100%;
    z-index: 2;
    border: none;
    overflow: hidden;
    border-radius: 0 0 10px 10px;
    height: auto;
    overflow-y: auto;
    max-height: 30vh;
    overflow-y: auto;
  }

  // .v-select__value.suggestionFix

  &__value.suggestionFix {
    border-radius: 10px 10px 0 0;
  }

  // .v-select__option

  &__option {
    cursor: pointer;
    transition: 0.3s;
    padding: 14px;
    white-space: break-spaces;
    transition: 0.3s ease;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }
    &:hover {
      background-color: $unactive-accent;
    }
  }

  // .v-select-pre__text

  &-pre__text {
    white-space: nowrap;
  }
}
.icon-revert {
  transform: rotate(180deg);
}

@media (max-width: 1600px) {
  .v-select {
    // .v-select__label

    &__label {
      line-height: 25px;
      font-size: 14px;
    }

    // .v-select__value

    &__value {
      height: 40px;
    }

    // .v-select__list

    &__list {
    }

    // .v-select__option

    &__option {
    }
  }
}
@media (max-width: 1200px) {
  .v-select {
    // .v-select__label

    &__label {
      line-height: 25px;
    }

    // .v-select__value

    &__value {
    }

    // .v-select__list

    &__list {
    }

    // .v-select__option

    &__option {
    }
  }
}
@media (max-width: 768px) {
  .v-select {
    // .v-select__label

    &__label {
      font-size: 13px;
    }

    // .v-select__value

    &__value {
    }

    // .v-select__list

    &__list {
    }

    // .v-select__option

    &__option {
    }
  }
}
</style>
