// import { string } from "../../../helpers/converters";

export default {
  CART(state) {
    return state.cart;
  },
  SUBTOTAL(state) {
    // console.log(Object.values(state.cart));
    if (state.cart.length) {
      state.cart_getters.subtotal = Object.values(state?.cart)
        .filter((item) => item?.promotion !== "Акционный товар")
        .map((item) => item?.price * item?.count)
        .reduce((a, b) => a + b)
        ?.toString();
      return state.cart_getters.subtotal;
    } else {
      return "0";
    }
  },
  TOTAL(state) {
    return state.cart_getters.subtotal;
  },
  CART_DATA(state) {
    return state.cart_info;
  },
  ADRESSES(state) {
    return state.adresses;
  },
  PROMOCODES(state) {
    return state.promocodes;
  },
  ORDER(state) {
    return state.order;
  },
};
