export default {
  ADMIN_LAYOUT: (state) => {
    return state.admin_layout;
  },
  SERVER_ERROR: (state) => {
    return state.server_errors;
  },
  INGREDIENTS: (state) => {
    return state.ingredients;
  },
  FOLDERS: (state) => {
    return state.folders;
  },
  FOLDER_IMAGES: (state) => {
    return state.folder_images;
  },
  ADMIN_BANNERS: (state) => {
    return state.admin_banners;
  },
  ADMIN_PROMOTIONS: (state) => {
    return state.admin_promotions;
  },
  ADMIN_CATEGORIES: (state) => {
    return state.admin_categories;
  },
  ADMIN_PRODUCTS: (state) => {
    return state.admin_products;
  },
  ADMIN_ADDRESSES: (state) => {
    return state.admin_addresses;
  },
  ADMIN_STUBS: (state) => {
    return state.admin_stubs;
  },
  ADMIN_AFFILATES: (state) => {
    return state.admin_affilates;
  },
};
