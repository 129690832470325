const settings = {
  working_schedule: [
    {
      day: "Воскресенье",
      number_of_day: 0,
      day_start: "11:00",
      day_end: "22:00",
    },
    {
      day: "Понедельник",
      number_of_day: 1,
      day_start: "10:00",
      day_end: "22:00",
    },
    {
      day: "Вторник",
      number_of_day: 2,
      day_start: "10:00",
      day_end: "22:00",
    },
    {
      day: "Среда",
      number_of_day: 3,
      day_start: "10:00",
      day_end: "22:00",
    },
    {
      day: "Четверг",
      number_of_day: 4,
      day_start: "10:00",
      day_end: "22:00",
    },
    {
      day: "Пятница",
      number_of_day: 5,
      day_start: "10:00",
      day_end: "23:00",
    },
    {
      day: "Суббота",
      number_of_day: 6,
      day_start: "11:00",
      day_end: "23:00",
    },
  ],
};

export { settings };
