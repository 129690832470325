import { sameAs } from "./sameAs";

function sortArray(arr, key = null, ...params) {
  let settings = params?.[0];
  if (settings?.sameAs) {
    return sameAs(settings.val, arr, "name");
  }
  console.log("eee");
  return arr?.sort((a, b) => a?.[key] - b?.[key]);
}

export { sortArray };
