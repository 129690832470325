import axios from "axios";
import apiRequest from "../../api-request";
import store from "../../index";

export default {
  IS_AUTH({ commit }, payload) {
    commit("SET_IS_AUTH", payload);
  },
  async GET_TEST_PRODUCT() {
    return await apiRequest.function("GET", "/testProduct", []);
  },
  async PUSH_NEW_CATEGORY({ commit }, payload) {
    commit, payload;
    payload.category_image =
      `${process.env.VUE_APP_API_URL}/category-icons/` +
      payload.category_show_image.name;

    let formData = new FormData();

    formData.append("file", payload.category_show_image);
    formData.append("obj", JSON.stringify(payload));

    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API_URL}/addCategory`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        path: "category-icons",
      },
    }).then((res) => {
      res;
      // commit("SEND_ORDER_DATA", order_data);
      commit;
    });
  },
  async DELETE_IMAGE({ commit }, payload) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API_URL}/deleteImage`,
      data: { img: payload.name },
      headers: {
        path: payload.path,
      },
    }).then((res) => {
      res;
      // commit("SEND_ORDER_DATA", order_data);
      commit;
    });
  },
  async ADMIN_SETTINGS({ commit }, payload) {
    commit("SET_ADMIN_SETTINGS", payload);
  },
  async PUSH_NEW_PRODUCT({ commit }, form) {
    commit;
    let payload = {};
    console.log(form);
    for (const key in form) {
      const el = form[key];
      // console.log(el);
      payload[key] = el.value;
      // console.log(element);
    }
    console.log(payload);
    apiRequest;
    // return await apiRequest.function(
    //   "POST",
    //   "/appendProduct",
    //   [],
    //   null,
    //   payload
    // );
  },
  async GET_INGREDIENTS() {
    await apiRequest.function(
      "GET",
      "/ingredients",
      ["SET_INGREDIENTS"],
      undefined,
      undefined
    );
    // console.log(res);
  },
  async GET_FOLDERS() {
    await apiRequest.function(
      "GET",
      "/getFolders",
      ["SET_ADMIN_FOLDERS"],
      undefined,
      undefined,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_FOLDER_IMAGES({ commit }, data) {
    commit;
    await apiRequest.function(
      "GET",
      "/getFolderImages",
      ["SET_FOLDER_IMAGES"],
      data,
      undefined,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async SET_FOLDER_IMAGE({ commit }, { folder, image }) {
    commit;
    let formData = new FormData();
    formData.append("file", image);

    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API_URL}/setFolderImage`,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        path: folder,
        Authorization: `Bearer ${store.state.token}`,
      },
    });
  },
  async DELETE_FOLDER_IMAGE({ commit }, data) {
    commit;
    return await apiRequest.function(
      "POST",
      "/deleteFolderImage",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_INGREDIENT({ commit }, data) {
    commit;
    let payload = {};
    let oldForm = {};
    let newForm = {};
    for (const key in data.newForm) {
      if (Object.hasOwnProperty.call(data.newForm, key)) {
        const element = data.newForm[key];
        if (key !== "search") {
          newForm[key] = element.value;
        }
      }
    }
    for (const key in data.oldForm) {
      if (Object.hasOwnProperty.call(data.oldForm, key)) {
        const element = data.oldForm[key];
        if (key !== "search") {
          oldForm[key] = element.value;
        }
      }
    }
    payload.newForm = newForm;
    payload.oldForm = oldForm;
    return await apiRequest.function(
      "POST",
      "/changeIngredient",
      [],
      undefined,
      payload,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_INGREDIENT({ commit }, data) {
    commit;
    return await apiRequest.function(
      "POST",
      "/deleteIngredient",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async APPEND_INGREDIENT({ commit }, data) {
    commit;
    let ingredient = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (key !== "search") {
          ingredient[key] = element.value;
        }
      }
    }
    console.log(ingredient);
    return await apiRequest.function(
      "POST",
      "/appendIngredient",
      [],
      undefined,
      ingredient,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_ADMIN_BANNERS() {
    await apiRequest.function(
      "GET",
      "/banners",
      ["SET_ADMIN_BANNERS"],
      undefined,
      undefined,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async APPEND_BANNER({ commit }, data) {
    commit;
    let banner = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (key !== "search") {
          banner[key] = element.value;
        }
      }
    }
    return await apiRequest.function(
      "POST",
      "/appendBanner",
      [],
      undefined,
      banner,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_BANNER({ commit }, data) {
    commit;
    return await apiRequest.function(
      "POST",
      "/deleteBanner",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_BANNER({ commit }, data) {
    commit;
    let payload = {};
    let oldForm = {};
    let newForm = {};
    for (const key in data.newForm.inputs) {
      if (Object.hasOwnProperty.call(data.newForm.inputs, key)) {
        const element = data.newForm.inputs[key];
        if (key !== "search") {
          newForm[key] = element.value;
        }
      }
    }
    for (const key in data.oldForm.inputs) {
      if (Object.hasOwnProperty.call(data.oldForm.inputs, key)) {
        const element = data.oldForm.inputs[key];
        if (key !== "search") {
          oldForm[key] = element.value;
        }
      }
    }
    payload.newForm = newForm;
    payload.oldForm = oldForm;
    return await apiRequest.function(
      "POST",
      "/changeBanner",
      [],
      undefined,
      payload,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_ADMIN_STUBS() {
    await apiRequest.function(
      "GET",
      "/stubs",
      ["SET_ADMIN_STUBS"],
      undefined,
      undefined,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_ADMIN_AFFILATES() {
    await apiRequest.function(
      "GET",
      "/affilates",
      ["SET_ADMIN_AFFILATES"],
      undefined,
      undefined
    );
  },
  async APPEND_AFFILATE({ commit }, data) {
    commit;
    let affilate = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        affilate[key] = element.value;
      }
    }
    return await apiRequest.function(
      "POST",
      "/appendAffilate",
      [],
      undefined,
      affilate,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_AFFILATE({ commit }, data) {
    commit;
    return await apiRequest.function(
      "POST",
      "/deleteAffilate",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_AFFILATE({ commit }, data) {
    commit;
    let payload = {};
    let oldForm = {};
    let newForm = {};
    for (const key in data.newForm.inputs) {
      if (Object.hasOwnProperty.call(data.newForm.inputs, key)) {
        const element = data.newForm.inputs[key];
        newForm[key] = element.value;
      }
    }
    for (const key in data.oldForm.inputs) {
      if (Object.hasOwnProperty.call(data.oldForm.inputs, key)) {
        const element = data.oldForm.inputs[key];
        oldForm[key] = element.value;
      }
    }
    for (const key in data.newForm.checkboxes) {
      if (Object.hasOwnProperty.call(data.newForm.checkboxes, key)) {
        const element = data.newForm.checkboxes[key];
        newForm[key] = element?.value?.length ? element.value?.[0] || "" : "";
      }
    }
    for (const key in data.oldForm.checkboxes) {
      if (Object.hasOwnProperty.call(data.oldForm.checkboxes, key)) {
        const element = data.oldForm.checkboxes[key];
        oldForm[key] = element?.value?.length ? element.value?.[0] || "" : "";
      }
    }
    payload.newForm = newForm;
    payload.oldForm = oldForm;
    console.log(payload);
    return await apiRequest.function(
      "POST",
      "/changeAffilate",
      [],
      undefined,
      payload,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async APPEND_STUB({ commit }, data) {
    commit;
    let stub = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (key !== "search") {
          stub[key] = element.value;
        }
      }
    }
    return await apiRequest.function(
      "POST",
      "/appendStub",
      [],
      undefined,
      stub,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_STUB({ commit }, data) {
    commit;
    return await apiRequest.function(
      "POST",
      "/deleteStub",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_STUB({ commit }, data) {
    commit;
    let payload = {};
    let oldForm = {};
    let newForm = {};
    for (const key in data.newForm.inputs) {
      if (Object.hasOwnProperty.call(data.newForm.inputs, key)) {
        const element = data.newForm.inputs[key];
        newForm[key] = element.value;
      }
    }
    for (const key in data.oldForm.inputs) {
      if (Object.hasOwnProperty.call(data.oldForm.inputs, key)) {
        const element = data.oldForm.inputs[key];
        oldForm[key] = element.value;
      }
    }
    payload.newForm = newForm;
    payload.oldForm = oldForm;
    console.log(payload);
    return await apiRequest.function(
      "POST",
      "/changeStub",
      [],
      undefined,
      payload,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_ADMIN_PROMOTIONS() {
    await apiRequest.function(
      "GET",
      "/promocodes",
      ["SET_ADMIN_PROMOTIONS"],
      undefined,
      undefined,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async APPEND_PROMOTION({ commit }, data) {
    commit;
    let promotion = {};
    promotion.conditions = {};

    if (data.time_start.value !== "" && data.time_end.value !== "")
      promotion.conditions.time = {};

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (key == "price" && element.value !== "") {
          promotion.conditions[key] = +element.value;
        }
        if (
          (key == "time_start" || key == "time_end") &&
          element.value !== ""
        ) {
          if (key == "time_start")
            promotion.conditions.time.start = +element.value;
          if (key == "time_end") promotion.conditions.time.end = +element.value;
        }
        if (key == "products" && element.value.length) {
          promotion.conditions[key] = element.value;
        }
        if (
          key !== "price" &&
          key !== "time_start" &&
          key !== "time_end" &&
          key !== "products" &&
          key !== "discount"
        )
          promotion[key] = element.value;
        if (key == "discount") promotion[key] = +element.value;
      }
    }

    return await apiRequest.function(
      "POST",
      "/appendPromotion",
      [],
      undefined,
      promotion,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_PROMOTION({ commit }, data) {
    commit;
    return await apiRequest.function(
      "POST",
      "/deletePromotion",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_PROMOTION({ commit }, data) {
    commit;
    console.log(data);
    let promotion = {};
    let oldName = data.oldForm.name.value;
    promotion.conditions = {};

    if (
      data.newForm.time_start.value !== "" &&
      data.newForm.time_end.value !== ""
    )
      promotion.conditions.time = {};

    for (const key in data.newForm) {
      if (Object.hasOwnProperty.call(data.newForm, key)) {
        const element = data.newForm[key];
        if (key == "price" && element.value !== "") {
          promotion.conditions[key] = +element.value;
        }
        if (
          (key == "time_start" || key == "time_end") &&
          element.value !== ""
        ) {
          if (key == "time_start")
            promotion.conditions.time.start = +element.value;
          if (key == "time_end") promotion.conditions.time.end = +element.value;
        }
        if (key == "products" && element.value.length) {
          promotion.conditions[key] = element.value;
        }
        if (
          key !== "price" &&
          key !== "time_start" &&
          key !== "time_end" &&
          key !== "products" &&
          key !== "discount"
        ) {
          promotion[key] = element.value;
        }
        if (key == "discount") promotion[key] = +element.value;
      }
    }
    return await apiRequest.function(
      "POST",
      "/changePromotion",
      [],
      undefined,
      {
        oldName,
        promotion,
      },
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_ADMIN_CATEGORIES() {
    await apiRequest.function(
      "GET",
      "/categories",
      ["SET_ADMIN_CATEGORIES"],
      undefined,
      undefined,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async APPEND_CATEGORY({ commit }, data) {
    commit;
    let category = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (key == "category_id" || key == "category_position")
          category[key] = +element.value;
        else category[key] = element.value;
      }
    }
    return await apiRequest.function(
      "POST",
      "/appendCategory",
      [],
      undefined,
      category,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_CATEGORY({ commit }, data) {
    commit;
    console.log(data);
    return await apiRequest.function(
      "POST",
      "/deleteCategory",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_CATEGORY({ commit }, data) {
    commit;
    let newCategory = data.newForm,
      oldName = data.oldForm.category_name.value,
      category = {};

    for (const key in newCategory) {
      if (Object.hasOwnProperty.call(newCategory, key)) {
        const element = newCategory[key];
        if (key == "category_id" || key == "category_position")
          category[key] = +element.value;
        else category[key] = element.value;
      }
    }
    return await apiRequest.function(
      "POST",
      "/changeCategory",
      [],
      undefined,
      {
        oldName,
        category,
      },
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_ADMIN_PRODUCTS({ commit }, id) {
    commit;
    return await apiRequest.function(
      "GET",
      "/getProductsFromCategory",
      ["SET_CATEGORY_PRODUCTS_TO_ADMIN_STATE"],
      id,
      undefined,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async APPEND_PRODUCT({ commit }, data) {
    commit;
    // console.log(data);
    const newProduct = data;
    let product = {};
    if (newProduct.category_name.value == "Пиццы") {
      product.modifications = [];
      product.modifications[0] = {
        id: 0,
        name: "25 см",
        modification: true,
        price: 0,
        fixed_price: 0,
        count: 0,
        image: "",
        selected: true,
        required: false,
      };
      product.modifications[1] = {
        id: 0,
        name: "30 см",
        modification: true,
        price: 0,
        fixed_price: 0,
        count: 0,
        image: "",
        selected: false,
        required: false,
      };
    }
    for (const key in newProduct) {
      if (Object.hasOwnProperty.call(newProduct, key)) {
        const element = newProduct[key];
        if (
          key == "calory" ||
          key == "price" ||
          key == "position" ||
          key == "id" ||
          key == "category_id"
        ) {
          product[key] = +element.value;
        } else product[key] = element.value;
      }
    }

    if (newProduct.category_name.value == "Пиццы") {
      product.price = +newProduct.price.value?.split("/")[0];
      product.fixed_price = +newProduct.price.value?.split("/")[0];
      product.total_price = +newProduct.price.value?.split("/")[0];
      product.id = +newProduct.id.value?.split("/")[0];
      product.modifications[0].id = +newProduct.id.value?.split("/")[0];
      product.modifications[1].id = +newProduct.id.value?.split("/")[1];
      product.modifications[0].price = +newProduct.price.value?.split("/")[0];
      product.modifications[1].price = +newProduct.price.value?.split("/")[1];
      product.modifications[0].fixed_price =
        +newProduct.price.value?.split("/")[0];
      product.modifications[1].fixed_price =
        +newProduct.price.value?.split("/")[1];
    } else {
      product.price = +newProduct.price.value;
      product.fixed_price = +newProduct.price.value;
      product.total_price = +newProduct.price.value;
      product.id = +newProduct.id.value;
    }
    product.count = 1;
    product.favorites = "false";
    product.second_category_name = "";
    // product.modifications = Array.from(product.modifications);

    return await apiRequest.function(
      "POST",
      "/appendProduct",
      [],
      undefined,
      product,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_PRODUCT({ commit }, data) {
    commit;
    console.log(data);
    return await apiRequest.function(
      "POST",
      "/deleteProduct",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_PRODUCT({ commit }, data) {
    commit;
    let newForm = data.newForm,
      oldName = data.oldForm.name.value,
      oldForm = data.oldForm,
      product = {};
    oldName, product, oldForm;
    product.modifications = [];
    if (newForm.category_name.value == "Пиццы") {
      product.modifications[0] = {
        id: 0,
        name: "25 см",
        modification: true,
        price: 0,
        fixed_price: 0,
        count: 0,
        image: "",
        selected: true,
        required: false,
      };
      product.modifications[1] = {
        id: 0,
        name: "30 см",
        modification: true,
        price: 0,
        fixed_price: 0,
        count: 0,
        image: "",
        selected: false,
        required: false,
      };
    }
    for (const key in newForm) {
      if (Object.hasOwnProperty.call(newForm, key)) {
        const element = newForm[key];
        if (
          key == "calory" ||
          key == "price" ||
          key == "position" ||
          key == "id" ||
          key == "category_id"
        ) {
          product[key] = +element.value;
        } else product[key] = element.value;
      }
    }

    if (newForm.category_name.value == "Пиццы") {
      product.price = +newForm.price.value?.split("/")[0];
      product.fixed_price = +newForm.price.value?.split("/")[0];
      product.total_price = +newForm.price.value?.split("/")[0];
      product.id = +newForm.id.value?.split("/")[0];
      product.modifications[0].id = +newForm.id.value?.split("/")[0];
      product.modifications[1].id = +newForm.id.value?.split("/")[1];
      product.modifications[0].price = +newForm.price.value?.split("/")[0];
      product.modifications[1].price = +newForm.price.value?.split("/")[1];
      product.modifications[0].fixed_price =
        +newForm.price.value?.split("/")[0];
      product.modifications[1].fixed_price =
        +newForm.price.value?.split("/")[1];
    } else {
      product.price = +newForm.price.value;
      product.fixed_price = +newForm.price.value;
      product.total_price = +newForm.price.value;
      product.id = +newForm.id.value;
    }
    product.count = 1;
    product.favorites = "false";
    // product.modifications = Array.from(product.modifications);

    console.log(product);
    return await apiRequest.function(
      "POST",
      "/changeProduct",
      [],
      undefined,
      {
        oldName,
        product,
      },
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async APPEND_ADDRESS({ commit }, data) {
    commit;
    const newAddress = data;
    let address = {};
    for (const key in newAddress) {
      if (Object.hasOwnProperty.call(newAddress, key)) {
        const element = newAddress[key];
        address[key] = element.value;
        if (
          key == "affilate" &&
          (element.value == "null" || element.value == null)
        )
          address[key] = null;
        else address[key] = +element.value;
      }
    }
    return await apiRequest.function(
      "POST",
      "/appendAddress",
      [],
      undefined,
      address,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async DELETE_ADDRESS({ commit }, data) {
    commit;
    console.log(data);
    return await apiRequest.function(
      "POST",
      "/deleteAdress",
      [],
      undefined,
      data,
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async CHANGE_ADDRESS({ commit }, data) {
    commit;
    let newForm = data.newForm,
      oldName = data.oldForm.address.value,
      address = {};
    oldName, address;
    for (const key in newForm) {
      if (Object.hasOwnProperty.call(newForm, key)) {
        const element = newForm[key];

        if (key == "mileage" || key == "street_index" || key == "delivery_zone")
          address[key] = +element.value;
        else address[key] = element.value;
      }
      if (address.affilate == "null" || address.affilate == null)
        address.affilate = null;
      else address.affilate = +address.affilate || 32;
    }
    console.log(address);
    return await apiRequest.function(
      "POST",
      "/changeAddress",
      [],
      undefined,
      {
        oldName,
        address,
      },
      { Authorization: `Bearer ${store.state.token}` }
    );
  },
  async GET_ADMIN_ADDRESSES({ commit }) {
    commit;
    return await apiRequest.function(
      "GET",
      "/getAddresses",
      ["SET_ADMIN_ADDRESSES"],
      undefined,
      undefined,
      undefined
    );
  },
};
