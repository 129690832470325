<template>
  <div class="v-notification__list">
    <div
      class="v-notification__item"
      :class="{
        error: item?.status == 'error' || item?.status >= 400 || item?.status <= 500,
        warning: item?.status == 'warning',
        standart: !item?.status,
        success: item?.status == 200,
      }"
      v-for="(item, idx) in NOTIFICATIONS"
      :key="idx"
    >
      <h5 class="v-notification__item-title">
        {{ item?.title }}
      </h5>
      <p class="v-notification__item-text">
        {{ item?.text }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "v-notification",
  computed: {
    ...mapGetters(["NOTIFICATIONS"]),
  },
};
</script>
<style lang="scss" scoped>
.v-notification {
  /* .v-notification__list */

  &__list {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 20px;
    right: 20px;
    max-width: 300px;
    z-index: 999;
    .error {
      background-color: tomato;
    }
    .warning {
      background-color: yellow;
    }
    .standart {
      background-color: gray;
    }
    .success {
      background-color: greenyellow;
      color: #000;
    }
  }

  /* .v-notification__item */

  &__item {
    padding: 18px;
    border-radius: 20px;
    margin-bottom: 12px;
  }

  /* .v-notification__item-title */

  &__item-title {
    margin-bottom: 12px;
    font-size: 20px;
  }

  /* .v-notification__item-text */

  &__item-text {
  }
}
</style>
