<template>
  <section class="banner__list column__banner-list">
    <v-banner-item
      v-for="item in FILTER_BANNERS"
      :key="item.promo_name"
      :banner_data="item"
      @bannerClick="bannerClick"
    ></v-banner-item>
  </section>
</template>
<script>
import vBannerItem from "@/components/column_banners/v-banner-item";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "v-banner-list",
  components: {
    vBannerItem,
  },
  methods: {
    ...mapActions(["GET_BANNERS"]),
    bannerClick(banner) {
      const { action, description, promo_name } = banner;
      if (action == "Открыть диалоговое окно") {
        this.$store.state.banner_data.options.visible = true;
        this.$store.state.banner_data.description = description;
      }
      if (action == "Редирект + применение") {
        localStorage.setItem("setPromo", promo_name);
        this.$router.push("/cart-overview");
      }
    },
  },
  computed: {
    ...mapGetters(["SET_BANNERS"]),
    FILTER_BANNERS() {
      return this.SET_BANNERS.filter((el) => el.location == "Внизу");
    },
  },
  mounted() {
    this.GET_BANNERS();
  },
};
</script>
<style lang="scss" scoped>
.banner {
  margin: 0 auto;
  &__list {
    display: flex;
    overflow: auto;
    padding: 0 5px;
    margin: 20px 0;
    margin-bottom: 0;
    img {
      max-width: 100%;
    }
  }
  &__item {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}
@media (max-width: 1920px) and (min-width: 1025px) {
  .banner {
    &__list {
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .banner {
    &__list {
      padding-left: 20px;
    }
    &__item {
      margin-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .banner {
    &__list {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 20px;
      margin-top: 10px;
    }
    &__item {
      max-width: 100%;
      margin: 0;
      margin-bottom: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 650px) {
  .banner {
    &__list {
      flex-direction: column;
      padding-right: 20px;
    }
    &__item {
      max-width: 100%;
      margin-right: 0;
    }
  }
}
</style>
