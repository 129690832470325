import vIcon from "@/components/UI/v-icon";
import vFavoriteBtn from "@/components/UI/v-favorite-btn";
import vBackMenu from "@/components/UI/v-back-menu";
// import vCategoryPopup from "@/components/UI/v-category-popup";
import vAddBtn from "@/components/UI/v-add-btn";
import vBtn from "@/components/UI/v-btn";
import vCardSmall from "@/components/UI/v-card-small";
import vProductPopup from "@/components/UI/v-product-popup";
import vBackBtn from "@/components/UI/v-back-button";
import vFavoriteBtnActive from "@/components/UI/v-favorite-btn-active";
import vFooter from "@/components/UI/v-footer";
import vPreloader from "@/components/UI/v-preloader";
import vPickupPopup from "@/components/UI/v-pickup-popup";
import vPopup from "@/components/UI/v-popup";
import vInput from "@/components/UI/v-input";
import vRadio from "@/components/UI/v-radio";
import vSelect from "@/components/UI/v-select";
import vEmpty from "@/components/UI/v-empty";
import vDialog from "@/components/UI/admin/v-dialog";
import vCheckbox from "@/components/UI/v-checkbox";
import vAccordeon from "@/components/UI/v-accordeon";

export default [
  vIcon,
  vFavoriteBtn,
  vBackMenu,
  vProductPopup,
  vBackBtn,
  vAddBtn,
  vCardSmall,
  vFavoriteBtnActive,
  vFooter,
  vPreloader,
  vPickupPopup,
  vPopup,
  vBtn,
  vInput,
  vRadio,
  vSelect,
  vEmpty,
  vDialog,
  vCheckbox,
  vAccordeon,
];
