// import { sortArray } from "../../../helpers/utils/sortArray";
// import { rub } from "../../../helpers/converters";

export default {
  ADD_TO_CART: (state, data) => {
    data.cart_item = true;
    let prod = Object.assign({}, data);

    if (state.cart.length) {
      let dataExist = false;

      state.cart.map((item) => {
        if (item.id === data.id) {
          dataExist = true;
        }
      });

      if (!dataExist) {
        state.cart.push(prod);
      }
    } else {
      state.cart.push(prod);
    }
  },
  SAVE_CART(state, data) {
    data;
    localStorage.setItem(
      "gurmani_cart",
      JSON.stringify(
        state.cart.filter((item) => item?.promotion !== "Акционный товар")
      )
    );
  },
  SET_SAVED_CART(state) {
    if (
      localStorage.getItem("gurmani_cart") &&
      JSON.parse(localStorage.getItem("gurmani_cart")).length &&
      JSON.parse(localStorage.getItem("gurmani_cart")) !== undefined
    ) {
      state.cart = JSON.parse(localStorage.getItem("gurmani_cart")).filter(
        (item) => item?.promotion !== "Акционный товар"
      );
    }
  },
  INCREMENT(state, data) {
    state;
    data.count++;
  },
  DECREMENT(state, data) {
    state;
    if (data.count > 1) {
      data.count--;
    } else if (data.modification == true) {
      if (data.count > 0) {
        data.count--;
      }
    } else if (data.cart_item == true) {
      data.count--;
    }
    if (data.count == 0) {
      state.cart = state.cart.filter((item) => item.count >= 1);
    }
  },
  SET_PICKUP_ADDRESS: (state, data) => {
    if (state.cart_data.delivery.type == "pickup") {
      state.pickup_address.affilate_address = data;
      state.pickup_address.affilate_number =
        data == "ул. Главная, 47" ? "32" : "";
    } else {
      state.pickup_address = {
        affilate_address: "",
        affilate_number: "",
      };
    }
  },
  SET_PROMOCODES: (state, data) => {
    state.promocodes = data;
  },
  SET_ADRESSES: (state, data) => {
    state.adresses = data;
  },
};
