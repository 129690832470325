<template>
  <main>
    <router-view />
  </main>
</template>
<script>
export default {
  name: "default-layout",
};
</script>
<style lang="scss"></style>
