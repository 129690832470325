import { createRouter, createWebHistory } from "vue-router";
import { getUserState } from "../firebase";
import store from "../store/index";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresUnAuth: false, layout: "default" },
  },
  {
    path: "/about",
    name: "About",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/conditions",
    name: "Conditions",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () =>
      import(/* webpackChunkName: "conditions" */ "../views/Conditions.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () =>
      import(/* webpackChunkName: "conditions" */ "../views/Privacy.vue"),
  },
  {
    path: "/favorite",
    name: "Favorite",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () =>
      import(/* webpackChunkName: "favorite" */ "../views/Favorite.vue"),
  },
  {
    path: "/menu",
    redirect: "/",
    name: "Menu",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () => import(/* webpackChunkName: "menu" */ "../views/Menu.vue"),
  },
  {
    path: "/products",
    name: "Products",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue"),
  },
  {
    path: "/thx",
    name: "Thx",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () => import(/* webpackChunkName: "thx" */ "../views/Thx.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { requiresUnAuth: false, layout: "default" },
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
  },
  {
    path: "/admin-dashboard",
    name: "Admin-dashboard",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-dashboard" */ "../views/Admin-dashboard.vue"
      ),
  },
  {
    path: "/admin-products",
    name: "ProductsViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-products" */ "../views/AdminViews/ProductsViews.vue"
      ),
  },
  {
    path: "/admin-promotions",
    name: "PromotionsViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-promotions" */ "../views/AdminViews/PromotionsViews.vue"
      ),
  },
  {
    path: "/admin-ingredients",
    name: "IngredientsViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-ingredients" */ "../views/AdminViews/IngredientsViews.vue"
      ),
  },
  {
    path: "/admin-banners",
    name: "BannersViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-banners" */ "../views/AdminViews/BannersViews.vue"
      ),
  },
  {
    path: "/admin-categories",
    name: "CategoriesViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-categories" */ "../views/AdminViews/CategoriesViews.vue"
      ),
  },
  {
    path: "/admin-addresses",
    name: "AddressesViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-addresses" */ "../views/AdminViews/AddressesViews.vue"
      ),
  },
  {
    path: "/admin-photos",
    name: "PhotosViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-photos" */ "../views/AdminViews/PhotosViews.vue"
      ),
  },
  {
    path: "/admin-zaglushki",
    name: "ZaglushkiViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-zaglushki" */ "../views/AdminViews/ZaglushkiViews.vue"
      ),
  },
  {
    path: "/admin-affilates",
    name: "AffilatesViews",
    meta: { requiresAuth: true, layout: "dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "admin-affilates" */ "../views/AdminViews/AffilatesViews.vue"
      ),
  },
  {
    path: "/cart-overview",
    name: "CartView",
    meta: { requiresAuth: false, layout: "default" },
    component: () =>
      import(
        /* webpackChunkName: "cart-overview" */ "../views/ClientViews/Cart.vue"
      ),
  },
];

// все наоборот requiresAuth - не требуется аутентификация, requiresUnAuth - требуется

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuth = await getUserState();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresUnAuth = to.matched.some(
    (record) => record.meta.requiresUnAuth
  );

  await store.dispatch("IS_AUTH", isAuth);

  if (requiresAuth && !isAuth) next("/auth");
  else if (!requiresUnAuth) next();
  else next();
});

export default router;
