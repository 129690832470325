<template>
  <v-header-desktop></v-header-desktop>
  <!-- <v-header-mobile v-if="clientWidth <= 1"></v-header-mobile> -->
  <div class="modification-warn" v-if="TOOLTIP">{{ this.TOOLTIP }}</div>
  <v-notification></v-notification>
  <v-settings></v-settings>
  <v-gurmani-closed
    v-if="this.display_warn_of_closed == true"
    @click="closeWarnOfClosed"
  ></v-gurmani-closed>
  <transition name="fade" mode="out-in">
    <v-popup :data="BANNER_POPUP" @closePopup="closeBannerPopup">
      <p>{{ BANNER_POPUP.description }}</p>
    </v-popup>
  </transition>
  <transition name="fade" mode="out-in">
    <v-popup :data="stubs_dialog" class="stubs__dialog">
      <h4 class="stubs__dialog-title">{{ stubs?.[0]?.value }}</h4>
      <p class="stubs__dialog-subtitle">{{ stubs?.[0]?.descr }}</p>
      <v-btn
        class="stubs__dialog-btn"
        @click="closeDialog(stubs_dialog)"
        v-if="stubs?.[0]?.btn_txt?.length"
      >
        {{ stubs?.[0]?.btn_txt }}
      </v-btn>
    </v-popup>
  </transition>

  <transition name="fade" mode="out-in">
    <component :is="layout">
      <router-view />
    </component>
  </transition>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import vHeaderDesktop from "@/components/v-header-top-desktop";
// import vHeaderMobile from "@/components/v-header-top-mobile";
import vGurmaniClosed from "@/components/v-gurmani-closed";
// import vNotification from "@/components/UI/admin/v-notification.vue";
import vNotification from "@/components/UI/v-notification.vue";
import vSettings from "@/components/UI/admin/v-settings.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    vHeaderDesktop,
    vGurmaniClosed,
    vNotification,
    vSettings,
    DefaultLayout,
    DashboardLayout,
  },
  data() {
    return {
      clientWidth: 0,
      display_warn_of_closed: false,
      close_warn_of_closed: false,
      stubs_dialog: {
        options: {
          closeBtn: false,
          visible: false,
        },
        title: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "SET_SAVED_FAVORITES",
      "GET_ADMIN_STUBS",
      "GET_SITE_SETTINGS",
    ]),
    displayWarnOfClosed() {
      let current_time = new Date().getHours();
      let current_day = new Date().getDay();
      let time_start = undefined;
      let time_end = undefined;

      switch (current_day) {
        case 5:
          time_start = 10;
          time_end = 23;
          break;
        case 6:
          time_start = 11;
          time_end = 23;
          break;
        case 7:
          time_start = 11;
          time_end = 22;
          break;
        default:
          time_start = 10;
          time_end = 22;
          break;
      }

      if (localStorage.getItem("TIME_WARN_DISPLAYED") == null) {
        localStorage.setItem("TIME_WARN_DISPLAYED", false);
      }

      let warn = JSON.parse(localStorage.getItem("TIME_WARN_DISPLAYED"));

      if (current_time >= time_end || current_time < time_start) {
        if (warn !== true) {
          this.display_warn_of_closed = true;
          localStorage.setItem("TIME_WARN_DISPLAYED", true);
        }
      } else {
        this.display_warn_of_closed = false;
        localStorage.setItem("TIME_WARN_DISPLAYED", false);
      }
    },
    closeWarnOfClosed(e) {
      if (
        e.target.classList.contains("bg") ||
        e.target.classList.contains("closed-popup__btn")
      ) {
        this.display_warn_of_closed = false;
      }
    },
    closeBannerPopup(e) {
      if (
        e.target.classList.contains("popup") ||
        e.target.classList.contains("popup__close")
      ) {
        this.$store.state.banner_data.options.visible = false;
      }
    },
    async closeDialog(dialog) {
      dialog.options.visible = false;
    },
    async openDialog(dialog) {
      dialog.options.visible = true;
    },
    // async setSiteSettings() {
    //   await this.GET_SITE_SETTINGS();
    // },
  },
  async mounted() {
    await this.GET_ADMIN_STUBS();
    this.SET_SAVED_FAVORITES(localStorage.getItem("SAVED_FAVORITES"));
    this.clientWidth = window.innerWidth;
    this.displayWarnOfClosed();
  },
  computed: {
    ...mapGetters(["TOOLTIP", "BANNER_POPUP", "ADMIN_STUBS", "SITE_SETTINGS"]),
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    },
    stubs() {
      return this.ADMIN_STUBS;
    },
  },
  watch: {
    $route: {
      handler() {
        if (
          this.ADMIN_STUBS?.length &&
          this.$route?.meta?.layout !== "dashboard"
        ) {
          this.stubs_dialog.options.visible = true;
          this.stubs_dialog.title = this.stubs?.[0]?.name;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.stubs {
  // .stubs__dialog

  &__dialog {
    .popup__body {
      max-width: 320px !important;
      align-items: center !important;
    }
  }

  // .stubs__dialog-title

  &__dialog-title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  // .stubs__dialog-subtitle

  &__dialog-subtitle {
    font-size: 16px;
    margin-bottom: 12px;
  }

  // .stubs__dialog-btn

  &__dialog-btn {
    text-align: center;
  }
}
#app {
  padding-top: 10px;
  padding-bottom: 60px;
}
.navbar {
  &__item {
    position: relative;
    svg {
      path {
        fill: $black;
      }
    }
    span {
      display: none;
    }
    &-counter {
      background-color: $accent;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: $white;
      position: absolute;
      top: -2px;
      right: 9px;
    }
    .burger {
      path {
        stroke: $black;
      }
    }
  }
}
.modification-warn {
  position: absolute;
  top: 60px;
  right: 60px;
  background-color: tomato;
  padding: 20px;
  border-radius: 20px;
  color: #fff;
  z-index: 5;
}
.router-link-active {
  @include container;
  background-color: $accent;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.3s background-color;
  -o-transition: 0.3s background-color;
  transition: 0.3s background-color;
  span {
    display: inline;
  }
  svg {
    path {
      fill: $white;
    }
  }
  .burger {
    path {
      stroke: $white;
    }
  }
}
.burger {
  path {
    stoke: $black;
  }
}
.plus-icon {
  width: 32px;
  height: 32px;
  path {
    fill: $black;
    stroke: $black;
  }
  .plus-icon-path {
    fill: $accent;
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

.animate-circle {
  position: fixed;
  left: -50%;
  bottom: -50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  background-color: $white;
  z-index: 3;
}
@media (max-width: 1920px) and (min-width: 1025px) {
  #app {
    max-width: 1280px;
    margin: 0 auto;
  }
  body::-webkit-scrollbar {
    height: 10px;
    background-color: $unactive-accent;
    border-radius: 10px;
  }
  body::-webkit-scrollbar-thumb {
    background-color: $accent;
    border-radius: 10px;
  }
  body::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
@media (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}
</style>


<style lang="scss" scoped>
</style>