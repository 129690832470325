export default {
  SET_IS_AUTH: (state) => {
    state.is_auth = false;
    return;
  },
  SET_SERVER_NOTIFICATION: (state, { status, message, warning }) => {
    state.server_error.message = message;
    state.server_error.status = status;
    state.server_error.warning = warning;
    state.server_error.flag = true;
    setTimeout(() => {
      state.server_error.flag = false;
    }, 3000);
    setTimeout(() => {
      state.server_error = {
        message: "",
        status: "",
        warning: "",
        flag: false,
      };
    }, 3500);
  },
  SET_ADMIN_SETTINGS: (state, payload) => {
    state.admin_settings = payload;
  },
  SET_INGREDIENTS: (state, res) => {
    let ingredients = [];
    res.map((item) => {
      ingredients.push({
        attrs: { label: item.name, id: item.name },
        value: item.name,
        image: item.image,
      });
    });
    state.ingredients = ingredients;
  },
  SET_ADMIN_FOLDERS: (state, res) => {
    let folders = [];
    res.map((item) => {
      folders.push({ attrs: { label: item, id: item }, value: item });
    });
    state.folders = folders;
  },
  SET_FOLDER_IMAGES: (state, res) => {
    let images = [];
    res.map((item) => {
      images.push({
        attrs: { label: item.name, id: item.name },
        value: item.name,
      });
    });
    state.folder_images = images;
  },
  SET_ADMIN_BANNERS: (state, res) => {
    let banners = [];
    res.map((item) => {
      banners.push({
        attrs: { label: item.promo_name, id: item.promo_name },
        value: item.promo_name,
        image: item.promo_picture,
        location: item.location,
        action: item.action,
      });
    });
    state.admin_banners = banners;
  },
  SET_ADMIN_STUBS: (state, res) => {
    let stubs = [];
    res.map((item) => {
      stubs.push({
        attrs: { label: item.title, id: item.title },
        value: item.title,
        descr: item.descr,
        btn_txt: item.btn_txt,
      });
    });
    state.admin_stubs = stubs;
  },
  SET_ADMIN_AFFILATES: (state, res) => {
    let affilates = [];
    res.map((item) => {
      affilates.push({
        attrs: { label: item.affilate_name, id: item.affilate_id },
        value: item.affilate_name,
        affilate_id: item.affilate_id,
        is_open: item.is_open,
        delivery_access: item.delivery_access,
        pickup_access: item.pickup_access,
      });
    });
    state.admin_affilates = affilates;
  },
  SET_ADMIN_PROMOTIONS: (state, res) => {
    let promotions = [];
    res.map((item) => {
      promotions.push({
        attrs: { label: item.name, id: item.name },
        value: item.name,
        conditions: item?.conditions,
        discount: item?.discount,
        type: item?.type,
      });
    });
    state.admin_promotions = promotions;
  },
  SET_ADMIN_CATEGORIES: (state, res) => {
    let categories = [];
    res.map((item) => {
      categories.push({
        attrs: { label: item.category_name, id: item.category_name },
        value: item.category_name,
        category_position: item?.category_position,
        category_color: item?.category_color,
        category_id: item?.category_id,
        category_text_color: item?.category_text_color,
        image: item?.category_image,
        hide: item?.hide,
      });
    });
    categories.sort(function (a, b) {
      return a.category_position - b.category_position;
    });
    state.admin_categories = categories;
  },
  SET_CATEGORY_PRODUCTS_TO_ADMIN_STATE: (state, res) => {
    res.map((item) => {
      item.attrs = { label: item.name, id: item.name };
      item.value = item.name;
    });
    res.sort(function (a, b) {
      return a.position - b.position;
    });
    state.admin_products = res;
  },
  SET_ADMIN_ADDRESSES: (state, res) => {
    res.map((item) => {
      item.attrs = { label: item.address, id: item.address };
      item.value = item.address;
    });
    state.admin_addresses = res;
  },
  // SET_ADMIN_ADDRESSES: (state, res) => {

  // },
};
