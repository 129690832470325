<template>
  <div
    class="popup"
    v-show="data?.options?.visible"
    @click="$emit('closePopup', $event)"
  >
    <div class="popup__body">
      <slot></slot>
      <button
        class="popup__close"
        v-if="data?.options?.closeBtn"
        @click="$emit('closePopup', $event)"
      >
        Закрыть
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "v-popup",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  // .popup__body

  &__body {
    background-color: #fff;
    max-width: 560px;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
  }
  // .popup__title

  &__title {
    margin-bottom: 36px;
  }

  // .popup__close

  &__close {
    margin-top: 24px;
    margin-right: 10px;
    padding: 14px;
    border: 1px solid $accent;
    background-color: $white;
    border-radius: 10px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
