<template>
  <section class="category">
    <h2 class="category__title"><slot></slot></h2>
    <div class="category__list">
      <button
        class="category__item-add"
        v-show="ADMIN_LAYOUT?.admin_emit"
        @click="openPopup"
      >
        Редактировать категории
      </button>
      <transition
        appear
        :css="false"
        @before-enter="beforeEnter"
        @enter="enter"
        v-show="ADMIN_LAYOUT?.admin_emit && popup_data.options.visible"
      >
        <v-category-item
          :category_data="category_form"
          :settings="{ admin_emit: ADMIN_LAYOUT?.admin_emit }"
        >
        </v-category-item>
      </transition>
      <transition-group
        appear
        :css="false"
        @before-enter="beforeEnter"
        @enter="enter"
      >
        <v-category-item
          v-for="(category, index) in getUnHideCategories"
          :key="index"
          :category_data="category"
          @getCategoryProducts="getCategoryProducts"
          :data-index="index"
        ></v-category-item>
      </transition-group>
    </div>
  </section>
</template>
<script>
import vCategoryItem from "@/components/categories/v-category-item";
import { mapActions, mapGetters } from "vuex";
import { gsap } from "gsap";
// Попробуй поработать с name файла, а не грузить картинки в base64, схема такая -> загрузка файла на сервак со всеми ключами -> нэйм присваиваем товару || категории, крч опционально, при редактировании удаляем файл с тем нэймом и загружаем новый

export default {
  name: "v-category-list",
  data() {
    return {
      popup_data: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить категорию",
      },
      category_form: {
        category_id: 0,
        category_name: "",
        category_color: "",
        category_text_color: "",
        category_show_image: "",
        category_image: "",
        hidden: false,
      },
      category_old_form: {
        category_id: 0,
        category_name: "",
        category_color: "",
        category_text_color: "",
        category_show_image: "",
        category_image: "",
        hidden: false,
      },
    };
  },
  props: {},
  computed: {
    ...mapGetters([
      "CATEGORIES",
      "CATEGORY_PRODUCTS",
      "IS_AUTH",
      "ADMIN_LAYOUT",
    ]),
    getUnHideCategories() {
      return Array.from(this.CATEGORIES).filter(
        (item) => item?.hide !== "Скрыть"
      );
    },
  },
  methods: {
    ...mapActions([
      "GET_CATEGORIES",
      "GET_CATEGORY_PRODUCTS_FROM_API",
      "PUSH_NEW_CATEGORY",
      "CHANGE_CATEGORY",
      "TEST",
    ]),
    async saveCategoryChanges() {
      let payload = {
        oldForm: this.category_old_form,
        newForm: this.category_form,
      };
      await this.CHANGE_CATEGORY(payload);
      this.$nextTick(() => {
        this.GET_CATEGORIES();
        this.category_old_form.category_id = this.category_form.category_id;
      });
    },
    setNewCategory() {},
    editCategory(data) {
      // this.category_form.category_id = data.category_id;
      // this.category_form.category_name = data.category_name;
      // this.category_form.category_color = data.category_color;
      // this.category_form.category_text_color = data.category_text_color;
      // this.category_form.category_show_image = data.category_image;
      // this.category_form.category_image = data.category_image;
      // this.category_form.hidden = data.hidden;
      this.category_form = data;
      this.category_old_form = Object.assign({}, data);

      if (process.env.NODE_ENV == "development") {
        this.category_form.category_show_image = data.category_image.slice(37);
      }
    },
    async addNewCategory(action) {
      if (action == "save") {
        await this.PUSH_NEW_CATEGORY(this.category_form);
        this.GET_CATEGORIES();
        this.category_form = {
          category_id: 0,
          category_name: "",
          category_color: "",
          category_text_color: "",
          category_image: "",
          category_show_image: "",
          category_position: 0,
          hidden: false,
        };
      }
    },
    onColorChange(e, target) {
      this.category_form[target] = e.target.value;
    },
    async onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let file = e.target.files[0];
      // let file_size = file.size;
      // let file_name = file.name;
      // let file_type = file.type;
      // this.category_form.category_image = file;
      this.category_form.category_show_image = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        var imgcode = e.target.result;
        this.category_form.category_image = imgcode;
      };
    },
    async getCategoryProducts(ID) {
      await this.GET_CATEGORY_PRODUCTS_FROM_API(ID);

      if (this.CATEGORY_PRODUCTS.length !== 0) {
        localStorage.setItem("categoryID", ID);
        this.$router.push("/products");
      }
    },
    async openPopup() {
      if (this.popup_data.options.visible)
        this.popup_data.options.visible = false;
      else this.popup_data.options.visible = true;
    },
  },
  mounted() {
    this.GET_CATEGORIES();
    // this.category_form.category_id =
    //   this.CATEGORIES.categories.slice(-1)[0].category_id + 1;
  },
  components: {
    vCategoryItem,
  },
  setup() {
    const beforeEnter = (el) => {
      el;
    };
    const enter = (el) => {
      el;
      gsap.fromTo(
        el,
        0.3,
        {
          opacity: 0,
          transform: "translateX(20px)",
          delay: el.dataset.index * 0.2,
        },
        {
          opacity: 1,
          transform: "translateX(0px)",
          delay: el.dataset.index * 0.2,
        }
      );
    };

    return { beforeEnter, enter };
  },
};
</script>
<style lang="scss" scoped>
.category {
  padding-bottom: 10px;
  &__title {
    @include h2;
  }
  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
  }

  // .category__item-add

  &__item-add {
    min-width: 160px;
    max-width: 160px;
    width: 100%;
    min-height: 138px;
    height: 100%;
    display: flex;
    border-radius: 10px;
    font-size: 16px;
    margin-right: 14px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: $white;
  }
}
.popup {
  // .popup__container

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
  }

  // .popup__wrapper

  &__wrapper {
    display: flex;
    align-items: center;
    button {
      margin-left: 14px;
    }
  }

  // .popup__input-file

  &__input-file {
  }

  // .popup__input-text

  &__input-text {
    padding: 8px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid $black;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
    &:focus {
      cursor: text;
    }
    &::placeholder {
      font-size: calc(100% - 2px);
    }
  }

  // .popup__input-colorpicker

  &__input-colorpicker {
    &:hover {
      cursor: pointer;
    }
  }

  // .popup__input-label

  &__input-label {
    display: flex;
    align-items: center;

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
    }
  }

  // .popup__input-checkbox

  &__input-checkbox {
    margin: 10px 0px 10px 10px;
  }
}

@media (max-width: 1920px) and (min-width: 1025px) {
  .category {
    &__list {
      padding-bottom: 10px;
    }
  }
}
@media (max-width: 1024px) and (min-width: 1px) {
  .category {
    &__title {
      margin-left: 0px;
    }
    &__list {
      padding-right: 20px;
      padding-left: 0px;
    }
  }
}
</style>
