import { createStore } from "vuex";
import axios from "axios";
import { settings } from "../helpers/site_settings";
import adminActions from "./modules/admin/actions";
import adminMutations from "./modules/admin/mutations";
import adminGetters from "./modules/admin/getters";
import adminState from "./modules/admin/state";
import cartActions from "./modules/cart/actions";
import cartMutations from "./modules/cart/mutations";
import cartGetters from "./modules/cart/getters";
import cartState from "./modules/cart/state";
import apiRequest from "./api-request";
import { sortArray } from "../helpers/utils/sortArray";

export default createStore({
  state: {
    site_settings: settings,
    homePageAnimateDone: false,
    ...adminState,
    ...cartState,
    banner_data: {
      options: {
        visible: false,
        closeBtn: true,
      },
    },
    appURL: process.env.VUE_APP_API_URL,
    notifications: [],
    allProducts: [],
    admin_settings: {
      admin_emit: false,
    },
    server_error: {
      message: "",
      status: "",
      flag: false,
    },
    categories: {
      categories: [],
    },
    is_auth: false,
    warning: "",
    error: "",
    tooltip: "",
    addresses: [],
    banners: [],
    category_products: [],
    promocodes: [],
    populars: [],
    favorites: [],
    affilates: [],
  },
  mutations: {
    ...adminMutations,
    ...cartMutations,
    TOGGLE_SIZE_OF_PIZZA: (state, data) => {
      state;
      console.log(data);
      data.PRODUCT_INFO.modifications.map((item) => {
        item.selected = false;
      });
      data.modification.selected = true;
      data.PRODUCT_INFO.id = data.modification.id;
      data.PRODUCT_INFO.fixed_price = data.modification.price;
      data.PRODUCT_INFO.price = data.modification.price;
    },
    SET_CATEGORIES_TO_STATE: (state, data) => {
      // state.categories = sortArray(data, "category_id");
      sortArray;
      data.sort(function (a, b) {
        return a.category_position - b.category_position;
      });
      state.categories = data;
    },
    SET_AFFILATES_TO_STATE: (state, data) => {
      state.affilates = data;
    },
    SET_CATEGORY_PRODUCTS_TO_STATE: (state, data) => {
      if (data[0].category_name == "Пиццы") {
        data.map((item) => {
          let price = item.modifications.filter(
            (item) => item.selected == true
          )[0];
          item.price = price.price;
          item.total_price = price.price;
          item.fixed_price = price.price;
        });
      }

      data = data.filter((item) => item?.promotion !== "Акционный товар");
      data.sort(function (a, b) {
        return a.position - b.position;
      });
      state.category_products = data;
    },
    SET_BANNERS: (state, banner) => {
      state.banners = banner;
    },
    SET_PRODUCT_INFO: (state, data) => {
      state.product = data;
    },
    RESET_PRODUCT: (state) => {
      state.product = {
        empty: true,
      };
    },
    SET_POPULARS_FROM_API: (state, data) => {
      state.populars = data;
    },
    SET_ADDRESSES: (state, data) => {
      data.map((item) => {
        switch (item.delivery_zone) {
          case 1:
            item.delivery_pay = 100;
            item.delivery_free = 600;
            break;
          case 2:
            item.delivery_pay = 100;
            item.delivery_free = 800;
            break;
          case 3:
            item.delivery_pay = 150;
            item.delivery_free = 1000;
            break;
          case 4:
            item.delivery_pay = 150;
            item.delivery_free = 1200;
            break;
          case 5:
            item.delivery_pay = 200;
            item.delivery_free = 1400;
            break;
          case 6:
            item.delivery_pay = 250;
            item.delivery_free = 1800;
            break;
          case 7:
            item.delivery_pay = 300;
            item.delivery_free = 2000;
            break;
          default:
            break;
        }
      });
      state.addresses = data;
    },
    SET_SELECTED_ADDRESS: (state, data) => {
      state.order.address = data;
    },
    SET_FAVORITES: (state, data) => {
      console.log(data.favorites);
      if (data.favorites == false) {
        data.favorites = true;
        state.favorites.push(data);
      } else {
        data.favorites = false;
        let index = state.favorites.indexOf(data);
        state.favorites.splice(index, 1);
      }
      console.log(data.favorites);
    },
    SAVE_FAVORITES: (state, data) => {
      state;
      data;

      localStorage.setItem(
        "SAVED_FAVORITES",
        JSON.stringify(state.favorites.map((item) => item))
      );
    },
    SET_SAVED_FAVORITES: (state, data) => {
      if (data) {
        JSON.parse(data).map((item) => state.favorites.push(item));

        for (const elem of state.favorites) {
          let sortedArr = state.populars.filter(
            (item) => item.name == elem.name
          );
          if (sortedArr.length) {
            sortedArr.map((item) => (item.favorites = true));
          }
        }

        for (const elem of state.favorites) {
          let sortedArr = state.category_products.filter(
            (item) => item.name == elem.name
          );
          if (sortedArr.length) {
            sortedArr.map((item) => (item.favorites = true));
          }
        }
      }
    },
    SET_NOTIFICATIONS: (state, notifications) => {
      state.notifications.push(notifications);
      setTimeout(() => {
        state.notifications.splice(0, 1);
      }, 8000);
    },
    SET_ALL_PRODUCTS: (state, data) => {
      state.allProducts = data;
    },
    SET_SITE_SETTINGS: (state, data) => {
      console.log("site settings", data);
      state.site_settings = data;
    },
  },
  actions: {
    ...adminActions,
    ...cartActions,
    GET_SITE_SETTINGS({ commit }, data) {
      commit("SET_SITE_SETTINGS", data);
    },
    TOGGLE_SIZE_OF_PIZZA({ commit }, data) {
      commit("TOGGLE_SIZE_OF_PIZZA", data);
    },
    GLOBAL_NOTIFICATIONS({ commit }, notifications) {
      commit("SET_NOTIFICATIONS", notifications);
    },
    FILTER_CART({ commit }) {
      commit("SET_FILTRED_CART");
    },
    DELIVERY_TYPE({ commit }, type) {
      commit("SET_DELIVERY_TYPE", type);
    },
    async GET_CATEGORIES() {
      await apiRequest.function("GET", "/categories", [
        "SET_CATEGORIES_TO_STATE",
      ]);
    },
    async GET_AFFILATES() {
      await apiRequest.function("GET", "/affilates", [
        "SET_AFFILATES_TO_STATE",
      ]);
    },
    async GET_ALL_PRODUCTS() {
      await apiRequest.function("GET", "/allProducts", ["SET_ALL_PRODUCTS"]);
    },
    async GET_CATEGORY_PRODUCTS_FROM_API({ commit }, ID) {
      commit;
      await apiRequest.function(
        "GET",
        "/getProductsFromCategory",
        ["SET_CATEGORY_PRODUCTS_TO_STATE"],
        ID
      );
    },
    GET_BANNERS({ commit }) {
      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/banners`,
      }).then((banners) => {
        commit("SET_BANNERS", banners.data);
      });
    },
    GET_POPULARS_FROM_API({ commit }) {
      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/getPopulars`,
      }).then((populars) => {
        commit("SET_POPULARS_FROM_API", populars.data);
      });
    },
    GET_PRODUCT_INFO({ commit }, data) {
      commit("SET_PRODUCT_INFO", data);
    },
    RESET_PRODUCT({ commit }) {
      commit("RESET_PRODUCT");
    },
    GET_ADDRESSES({ commit }) {
      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/getAddresses`,
      }).then((data) => {
        commit("SET_ADDRESSES", data.data);
      });
    },
    GET_ADDRESS({ commit }, data) {
      commit("SET_SELECTED_ADDRESS", data);
    },
    ADD_TO_FAVORITES({ commit }, data) {
      commit("SET_FAVORITES", data);
      commit("SAVE_FAVORITES", data);
    },
    SET_SAVED_FAVORITES({ commit }, data) {
      commit("SET_SAVED_FAVORITES", data);
    },
  },
  getters: {
    ...adminGetters,
    ...cartGetters,
    SITE_SETTINGS(state) {
      return {
        settings: state.site_settings,
        curr_day: {
          ...(state.site_settings.working_schedule?.filter(
            (day) => day?.number_of_day === new Date().getDay()
          )?.[0] || {}),
        },
      };
    },
    AFFILATES(state) {
      return state.affilates;
    },
    BANNER_POPUP(state) {
      return state.banner_data;
    },
    ALL_PRODUCTS(state) {
      return state.allProducts;
    },
    NOTIFICATIONS(state) {
      return state.notifications;
    },
    CATEGORIES(state) {
      return state.categories;
    },
    SET_BANNERS(state) {
      return state.banners;
    },
    CATEGORY_PRODUCTS(state) {
      return state.category_products;
    },
    PRODUCT_INFO(state) {
      return state.product;
    },
    POPULARS(state) {
      return state.populars;
    },
    ADDRESSES(state) {
      return state.addresses;
    },
    SENDED_ORDER(state) {
      return state.sended_order;
    },
    FAVORITES(state) {
      return state.favorites;
    },
    TOOLTIP(state) {
      return state.tooltip;
    },
    PICKUP_ADDRESS(state) {
      return state.pickup_address;
    },
  },
  modules: {},
});
