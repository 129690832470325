<template>
  <label
    class="BaseInput__label"
    :for="attrs.id"
    @focusout="focusOutHandler"
    @focus="focusHandler"
    tabindex="-1"
    :class="attrs?.id"
    :ref="attrs?.id"
    v-show="!attrs?.hidden"
  >
    <p
      class="BaseInput__label-text"
      v-if="
        (attrs.label && this.$route.href !== '/cart-overview') ||
        attrs.type == 'file'
      "
    >
      {{ attrs.label }}
      <slot name="label_append"></slot>
    </p>

    <div class="BaseInput__wrapper">
      <div
        class="BaseInput__wrapper-container"
        :class="{ append: this.$slots?.append }"
      >
        <input
          tabindex="0"
          class="BaseInput__wrapper-input"
          :class="{
            'input--error': errorBag.length > 0,
            suggestionFix:
              this.focus &&
              this.attrs?.suggestions?.toggle &&
              this.suggestionsArr.length,
            disabled: attrs?.disabled,
          }"
          :placeholder="attrs.placeholder"
          :style="{
            'max-width': attrs.width + 'px',
            height: attrs.height + 'px',
          }"
          :id="attrs.id"
          @input="updateModelValue($event)"
          :maxlength="attrs.v_maxlength"
          @focus="focusHandler"
          :value="typeof modelValue == 'string' ? modelValue : ''"
          :disabled="attrs.disabled"
          v-maska="attrs?.maska"
          :type="attrs?.type"
          v-show="typeof modelValue == 'string' || attrs?.type == 'file'"
        />
        <!-- {{
          typeof modelValue == "object"
            ? modelValue.map((item) => item?.name)
            : ""
        }} -->
        <span
          style="padding: 14px; color: #6b6b6b"
          v-if="Array.isArray(modelValue) && !modelValue.length"
        >
          {{ attrs.label }}
        </span>
        <div v-if="Array.isArray(modelValue)">
          <span v-for="(item, idx) in modelValue" :key="idx">
            {{ item + "" + (idx + 1 !== modelValue.length ? ", " : "") }}
          </span>
        </div>

        <!-- v-regex:[attrs.regex]="modelValue" -->
        <!-- <v-icon
          class="BaseInput__eye"
          v-if="attrs?.always_type == 'password'"
          :name="attrs?.type == 'password' ? 'eye_unactive' : 'eye_active'"
          @click="togglePwd"
          width="19"
          height="19"
        /> -->

        <slot name="append" />

        <transition name="fade" mode="out-in">
          <div
            class="BaseInput__wrapper-suggestions"
            v-if="this.attrs?.suggestions?.toggle"
            v-show="this.focus && this.suggestionsArr.length"
            tabindex="-1"
          >
            <div
              class="BaseInput__wrapper-suggestion"
              v-for="(item, idx) in this.suggestionsArr.slice(0, 6)"
              :key="idx"
              @click.prevent="selectSuggestions(item)"
            >
              {{ item?.[this?.attrs?.suggestions?.key] }}
            </div>
          </div>
        </transition>
      </div>
      <p class="BaseInput__error" ref="errors" v-show="errorBag?.length">
        {{ errorBag?.[0] }}
      </p>
      <slot name="inner"></slot>
    </div>
  </label>
</template>
<script>
import gsap from "gsap";

export default {
  name: "v-input",
  props: {
    modelValue: [String, Array, Object],
    attrs: {
      type: Object,
      default: () => {},
    },
    errorBag: {
      type: Array,
      default: () => [],
    },
    suggestValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      focus: false,
      config: {
        attributes: true,
        childList: true,
        subtree: true,
      },
      observer: new MutationObserver(this.callback),
      suggestionsArr: [],
    };
  },
  methods: {
    callback(mutationsList, observer) {
      observer;
      mutationsList;
      gsap;
      return;
      // for (let mutation of mutationsList) {
      //   if (mutation.type === "childList") {
      //     gsap.fromTo(
      //       this.$refs.errors,
      //       { opacity: 0, transition: 1000 },
      //       { opacity: 1, transition: 1000 }
      //     );
      //   } else if (mutation.type === "attributes") {
      //     observer;
      //   }
      // }
    },
    async onFileChange(e, type) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let file = e.target.files[0];
      // let file_size = file.size;
      // let file_name = file.name;
      let file_type = file.type;
      if (file_type == "image/png" || file_type == "image/jpeg") {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          // var imgcode = e.target.result;
          var imgcode = e.target.result;
          console.log(type);
          if (type == "categories") {
            // this.selfie = file;
            this.admin_settings.categories.template.category_image = imgcode;
          }
        };
      }
    },
    updateModelValueArray() {
      // if (Array.isArray(modelValue)) {
      //   this.$emit('')
      // }
      // return;
    },
    focusOutHandler() {
      this.focus = false;
    },
    focusHandler() {
      this.focus = true;
    },
    updateModelValue(e) {
      // if (this.attrs.chooseImage) return;
      if (this.attrs?.type == "file") {
        this.$emit("update:modelValue", e.target.files || e.dataTransfer.files);
      } else {
        this.$emit("update:modelValue", e.target.value);
      }
    },
    togglePwd(e) {
      e.preventDefault();
      this.$emit("togglePwd", this.attrs.id);
    },
    selectSuggestions(suggest) {
      this.$emit("selectSuggestion");
      this.$emit("update:modelValue", suggest?.[this?.attrs?.suggestions?.key]);
      this.$emit("update:suggestValue", suggest);
    },
  },
  mounted() {
    this.observer.observe(this.$refs.errors, this.config);
  },
  computed: {},
  watch: {
    modelValue: {
      handler() {
        if (this.attrs.type == "date") {
          console.log("");
        }
        if (this.attrs?.suggestions?.toggle) {
          let { items } = this.attrs?.suggestions,
            suggestions = Array.from(items).filter((item) =>
              item?.[this?.attrs?.suggestions?.key]
                .toLowerCase()
                .includes(this.modelValue.toLowerCase())
            );
          this.suggestionsArr = suggestions;
        }
      },
    },
    errorBag: {
      handler() {},
      deep: true,
    },
    suggestionsArr: {
      handler() {},
    },
  },
};
</script>
<style lang="scss" scoped>
.BaseInput {
  // .BaseInput__label

  &__label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    white-space: nowrap;
    min-height: 44px;
  }

  // .BaseInput__label-text

  &__label-text {
    font-size: 14px;
    line-height: 22px;
    color: $black;
    margin-bottom: 2px;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
  }

  // .BaseInput__wrapper

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: inherit;
  }

  // .BaseInput__wrapper-container

  &__wrapper-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    font-family: "Roboto", sans-serif;
    transition: 0.3s ease;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    flex: 1 1 auto;
    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
  }

  // .BaseInput__wrapper-container.append

  &__wrapper-container.append {
  }

  // .BaseInput__wrapper-input

  &__wrapper-input {
    background-color: transparent;
    outline: none;
    padding: 14px;
    padding-right: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    border: none;
    border-radius: 10px;
    &::placeholder {
      color: $second-black;
      font-size: 14px;
    }
  }

  // .BaseInput__wrapper-input.suggestionFix

  &__wrapper-input.suggestionFix {
    border-radius: 10px 10px 0 0;
  }

  // .BaseInput__wrapper-input.disabled

  &__wrapper-input.disabled {
    background-color: $unactive-accent;
  }

  // .BaseInput__eye

  &__eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  // .BaseInput__error

  &__error {
    font-family: "Roboto", sans-serif;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    padding: 0 14px;
    font-size: 12px;
    line-height: 130%;
    color: $accent;
    max-width: 100%;
    white-space: break-spaces;
    margin-top: 4px;
    span,
    a {
      color: inherit;
      border-bottom: 1px solid $accent;
      padding-bottom: 1px;
    }
  }

  // .BaseInput__wrapper-suggestions

  &__wrapper-suggestions {
    position: absolute;
    top: 100%;
    background-color: $white;
    z-index: 2;
    border-radius: 0 0 10px 10px;
    width: 100%;
    overflow: hidden;
  }

  // .BaseInput__wrapper-suggestion

  &__wrapper-suggestion {
    padding: 14px;
    white-space: break-spaces;
    &:hover {
      background-color: $unactive-accent;
    }
  }
}

@media (max-width: 1600px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
}
</style>
