export default {
  data() {
    return {
      focus: false,
      active_flag: false,
    };
  },
  methods: {
    focusHandler() {
      this.focus = true;
      this.active_flag = true;
    },
    focusOutHandler() {
      this.focus = false;
      this.active_flag = false;
    },
  },
};
