export default {
  cart: [],
  cart_info: {
    error: "",
    warning: "",
  },
  cart_getters: {
    subtotal: "0",
    total: "0",
  },
  adresses: [],
  promocodes: [],
  order: {},
};
