<template>
  <div class="BaseRadio" :class="[attrs?.id, { disabled }]">
    <input
      type="radio"
      :name="attrs?.name"
      :id="attrs?.id"
      :value="value"
      @change="$emit('update:modelValue', $event?.target?.value)"
      :checked="value === modelValue"
      class="BaseRadio__input"
      tabindex="-1"
    />

    <label
      :for="attrs?.id"
      class="BaseRadio__label"
      :class="{ 'BaseRadio__label--checked': value === modelValue }"
      tabindex="0"
    >
      <slot name="icon"> </slot>
      {{ attrs?.label }}
      <slot></slot>
      <slot name="append"> </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: "v-radio",
  props: {
    modelValue: { type: String, default: null },
    value: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    attrs: { type: Object, default: null },
  },
};
</script>
<style lang="scss">
.BaseRadio {
  // .BaseRadio__label

  &__label {
    display: flex;
    align-items: center;
    padding: 15px 14px !important;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    color: $second-black;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      background-color: $unactive-accent;
    }
    svg {
      transition: 0.3s ease;
      margin-right: 6px;
      path {
        transition: inherit;
      }
    }
  }

  // .BaseRadio__input

  &__input {
    appearance: none;
    pointer-events: none;
    opacity: 0;
    display: none;
  }
  // .BaseRadio__label--checked

  &__label--checked {
    background-color: $accent;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    color: $white;
    svg {
      path {
        fill: white;
      }
    }
  }

  &.disabled {
    .BaseRadio__label {
      background-color: $unactive-accent;
      pointer-events: none;
    }
  }
}
</style>
