<template>
  <transition name="confirm" :css="true" mode="out-in">
    <div class="v-empty">
      <img
        :src="require(`@/assets/media/img/${img}`)"
        class="v-empty__img"
        width="150"
        height="150"
        :alt="this.$attrs.alt || 'logo.svg'"
      />
      <slot>Пусто</slot>
    </div>
  </transition>
</template>
<script>
export default {
  name: "v-empty",
  props: {
    img: {
      type: String,
      default: () => "logo.svg",
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.v-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // .v-empty__img

  &__img {
    margin-bottom: 12px;
  }
}
</style>
