function sameAs(val, compare, key) {
  if (typeof compare == "object") {
    return compare.filter(
      (item) => item?.[key]?.toLowerCase() === val?.toLowerCase()
    )?.[0];
  }
  if (typeof compare == "string") {
    return val === compare;
  }
}

export { sameAs };
