<template>
  <section class="popular">
    <h2 class="popular__title">Популярное</h2>
    <div class="popular__list">
      <v-popular-item
        v-for="popular in POPULARS"
        :key="popular.id"
        :popular_data="popular"
      ></v-popular-item>
    </div>
  </section>
</template>
<script>
import vPopularItem from "@/components/populars/v-popular-item";
import { mapGetters } from "vuex";

export default {
  name: "v-popular-list",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["POPULARS"]),
  },
  methods: {},
  mounted() {},
  components: {
    vPopularItem,
  },
};
</script>
<style lang="scss">
.popular {
  &__title {
    @include h2;
  }
  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1920px) and (min-width: 1025px) {
  .popular {
    &__list {
      padding-bottom: 10px;
    }
  }
}
@media (max-width: 1024px) and (min-width: 1px) {
  .popular {
    &__title {
      @include h2;
      margin-left: 0px;
    }
  }
}

@media (max-width: 416px) {
  .popular {
    padding-right: 20px !important;
    &__list {
      justify-content: space-between;
    }
  }
}
</style>
