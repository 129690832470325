<template>
  <section class="banner">
    <div class="banner__list" ref="slider">
      <v-banner-item
        v-for="(item, idx) in FILTER_BANNERS"
        :key="item.promo_name"
        :ref="`top_banner${idx}`"
        :banner_data="item"
        @bannerClick="bannerClick"
      ></v-banner-item>
    </div>
  </section>
</template>
<script>
import vBannerItem from "@/components/banners/v-banner-item";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "v-banner-list",
  components: {
    vBannerItem,
  },
  data() {
    return {
      timeLine: null,
      currentIndex: 0,
      sliderWidth: 0,
      duration: 3, // Время между переходами в секундах
      slideInterval: null,
      slideMargin: 0,
      vw: 0, // clientWidth // viewport width
    };
  },
  methods: {
    ...mapActions(["GET_BANNERS"]),
    bannerClick(banner) {
      const { action, description, promo_name } = banner;
      if (action == "Открыть диалоговое окно") {
        this.$store.state.banner_data.options.visible = true;
        this.$store.state.banner_data.description = description;
      }
      if (action == "Редирект + применение") {
        localStorage.setItem("setPromo", promo_name);
        this.$router.push("/cart-overview");
      }
    },
    nextSlide() {
      this.currentIndex++;
      if (this.currentIndex >= this.FILTER_BANNERS.length) {
        this.currentIndex = 0;
      }
      this.animateSlide();
    },
    animateSlide() {
      try {
        const el = this.$refs.slider;
        el.scrollLeft += this.slideMargin;
      } catch (error) {
        error;
      }
    },
  },
  computed: {
    ...mapGetters(["SET_BANNERS"]),
    FILTER_BANNERS() {
      return this.SET_BANNERS.filter((el) => el.location == "Вверху");
    },
  },
  watch: {
    currentIndex: {
      handler(newVal) {
        const el = this.$refs.slider;
        if (newVal == 0) el.scrollLeft = 0;
      },
    },
    vw: {
      handler(newVal) {
        if (newVal >= 1920 || newVal > 1024) this.slideMargin = 230;
        if (newVal <= 1024) this.slideMargin = 400;
        if (newVal <= 768) this.slideMargin = 700;
        if (newVal <= 590) this.slideMargin = 540;
        if (newVal <= 500) this.slideMargin = 460;
        if (newVal <= 500) this.slideMargin = 460;
        if (newVal <= 415) this.slideMargin = 390;
        if (newVal <= 375) this.slideMargin = 360;
        if (newVal <= 350) this.slideMargin = 330;
        if (newVal <= 320) this.slideMargin = 310;
      },
    },
  },
  async mounted() {
    await this.GET_BANNERS().then(() => {
      this.sliderWidth = this.$refs.slider.offsetWidth;
      this.vw = window.innerWidth;
      setTimeout(() => {
        this.slideInterval = setInterval(this.nextSlide, this.duration * 1000);
      }, 4000);
    });
  },
  created() {
    window.addEventListener("resize", () => (this.vw = window.innerWidth));
  },
};
</script>
<style lang="scss" scoped>
.banner {
  margin: 0 auto;
  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
  }
}
@media (max-width: 1920px) and (min-width: 1025px) {
  .banner {
    &__list {
      padding: 0 10px;
      padding-left: 0;
      padding-bottom: 10px;
    }
  }
}
@media (max-width: 1025px) and (min-width: 320px) {
  .banner {
    &__list {
      padding: 0 20px;
      padding-left: 0;
      padding-bottom: 10px;
    }
  }
}
</style>
