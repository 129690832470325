<template>
  <button
    class="v-btn"
    :class="{
      standart: standart,
      icon: icon,
      admin: admin,
      accent: accent,
      disabled,
    }"
  >
    <p class="v-btn__prepend">
      <slot name="prepend"></slot>
    </p>
    <p class="v-btn__name">
      <slot></slot>
    </p>
    <p class="v-btn__append">
      <slot name="append"></slot>
    </p>
  </button>
</template>
<script>
export default {
  name: "v-btn",
  props: {
    icon: {
      type: Boolean,
      default: () => false,
    },
    admin: {
      type: Boolean,
      default: () => false,
    },
    standart: {
      type: Boolean,
      default: () => false,
    },
    accent: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.v-btn {
  border: 1px solid $accent;
  background-color: $white;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  // .v-btn__prepend

  &__prepend {
  }

  // .v-btn__name

  &__name {
  }

  // .v-btn__append

  &__append {
  }
}
.v-btn.accent {
  border: 1px solid $accent;
  background-color: $accent;
  color: $white;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  // .v-btn__prepend

  &__prepend {
  }

  // .v-btn__name

  &__name {
  }

  // .v-btn__append

  &__append {
  }
}
.v-btn.prepend {
  // .v-btn__prepend

  &__prepend {
  }

  // .v-btn__name

  &__name {
  }

  // .v-btn__append

  &__append {
  }
}

.v-btn.append {
  // .v-btn__prepend

  &__prepend {
  }

  // .v-btn__name

  &__name {
  }

  // .v-btn__append

  &__append {
  }
}
.v-btn.disabled {
  pointer-events: none;
  background-color: $unactive-accent;
  // .v-btn__prepend

  &__prepend {
  }

  // .v-btn__name

  &__name {
  }

  // .v-btn__append

  &__append {
  }
}
</style>
