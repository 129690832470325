<template>
  <div
    class="banner__item column__banner-item"
    ref="column_banner"
    @click="setPromo"
  >
    <img :src="banner_data.promo_picture" alt="" />
  </div>
</template>
<script>
export default {
  name: "v-banner-item",
  props: {
    banner_data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    setPromo() {
      this.$emit("bannerClick", this.banner_data);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.banner__item {
  margin-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  img {
    margin-right: 0;
  }
}

@media (max-width: 1025px) {
  .banner {
    /* .banner__item */

    &__item {
      img {
        max-width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
