<template>
  <div class="closed-popup">
    <div class="closed-popup__info">
      <img src="@/assets/media/img/logo.svg" alt="" />
      <p class="closed-popup__description">
        К сожалению мы закрыты , работаем с
        {{ SITE_SETTINGS?.curr_day?.day_start }} -
        {{ SITE_SETTINGS?.curr_day?.day_end }}. Вы можете оформить предзаказ ,
        оператор вам перезвонит
      </p>
      <button class="closed-popup__btn" @click="close_popup = true">
        Сделать предзаказ
      </button>
    </div>
    <div class="bg" @click="close_popup = true"></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "v-gurmani-closed",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["SITE_SETTINGS"]),
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.closed-popup {
  img {
    max-width: 150px;
  }
  &__info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 250px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @include container;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 11;
  }
  &__btn {
    border-radius: 10px;
    background-color: #ff6800;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    font-family: roboto, sans-serif;
    text-align: center;
    text-transform: uppercase;
  }
  &__description {
    margin: 12px 0;
    text-align: center;
  }
}
.bg {
  background-color: $second-black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.7;
}
</style>
