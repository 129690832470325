<template>
  <main class="dashboard-layout" ref="dashboard_layout">
    <v-back-menu class="panel__back-menu">
      <v-back-btn @click="signOutUser"></v-back-btn>
      <h2 class="panel__title">Gurmani dashboard</h2>
    </v-back-menu>
    <div class="panel__sections">
      <router-link to="/admin-products" class="panel__section-link">
        Блюда
      </router-link>
      <router-link to="/admin-categories" class="panel__section-link">
        Категории
      </router-link>
      <router-link to="/admin-promotions" class="panel__section-link">
        Акции
      </router-link>
      <router-link to="/admin-banners" class="panel__section-link">
        Баннеры
      </router-link>
      <router-link to="/admin-ingredients" class="panel__section-link">
        Ингредиенты
      </router-link>
      <router-link to="/admin-addresses" class="panel__section-link">
        Адреса
      </router-link>
      <router-link to="/admin-zaglushki" class="panel__section-link">
        Управление заглушками
      </router-link>
      <router-link to="/admin-affilates" class="panel__section-link">
        Управление филиалами
      </router-link>
      <div class="panel__section-link" @click="openDialog(image_dialog_data)">
        Управление файлами
      </div>
    </div>
    <v-dialog
      class="folders__dialog"
      :data="image_dialog_data"
      @adminSave="folderController('setFolderImage')"
      @adminDelete="folderController('deleteFolderImage')"
      @closeDialog="closeDialog"
    >
      <div class="folders__dialog-folders">
        <v-radio
          class="folders__dialog-folder"
          :value="folder.attrs.label"
          :attrs="folder.attrs"
          v-model="active_folder.value"
          v-for="(folder, idx) in FOLDERS"
          :key="idx"
          @change="folderController('getFolderImages')"
        >
        </v-radio>
      </div>

      <form class="folders__dialog-form">
        <v-input
          v-for="(field, idx) in image_form.fields"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="folders__dialog-file"
        ></v-input>
      </form>

      <!-- <img class="folders__dialog-preview" :src="preview_image" alt="" /> -->

      <div class="folders__dialog-images" v-if="filtredImages.length">
        <v-radio
          class="folders__dialog-control"
          :value="img.attrs.label"
          :attrs="img.attrs"
          v-model="active_image.value"
          v-for="(img, idx) in filtredImages"
          :key="idx"
          @change="folderController('getFolderImages')"
        >
          <template v-slot:append>
            <img
              :src="`${this.$store.state.appURL}/${this.activeFolder}/${img.value}`"
              alt=""
              class="folders__dialog-image"
              :ref="img.value"
              @click="folderController('selectImage', img.value)"
            />
          </template>
        </v-radio>
      </div>
    </v-dialog>
    <router-view />
  </main>
</template>
<script>
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "@/firebase";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { mapActions, mapGetters } from "vuex";
import { fields } from "../helpers/images_dialog_fields";
import { useStore } from "vuex";
export default {
  name: "dashboard-layout",
  data() {
    return {
      image_dialog_data: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление картинками",
      },
      image_form: {
        fields,
      },
      preview_image: "",
      active_folder: {
        value: "",
      },
      active_image: {
        value: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "GET_FOLDERS",
      "GET_FOLDER_IMAGES",
      "SET_FOLDER_IMAGE",
      "DELETE_FOLDER_IMAGE",
      "GLOBAL_NOTIFICATIONS",
    ]),
    openDialog(dialog) {
      dialog.options.visible = true;
    },
    openImagesStore() {
      this.image_dialog_data.options.visible = true;
    },
    async onFileChange(file) {
      // let files = e.target.files || e.dataTransfer.files;
      // if (!files.length) return;
      // let file = e.target.files[0];
      // // let file_size = file.size;
      // // let file_name = file.name;
      // let file_type = file.type;
      // console.log(file_type);
      // this.category_form.category_image = file;
      this.image_form.fields.image.value = file;

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        this.preview_image = e.target.result;
      };
    },
    async folderController(action, ...params) {
      let settings = params?.[0];
      console.log(settings);
      if (action == "getFolderImages") {
        this[action]({ folder: this.activeFolder });
      }
      if (action == "setFolderImage") {
        this[action]({
          folder: this.activeFolder,
          image: this.image_form.fields.image.value,
        });
      }
      if (action == "deleteFolderImage") {
        this[action]({
          folder: this.activeFolder,
          image: this.activeImage,
        });
      }
      // if (action == "selectImage") {
      //   this[action](settings);
      // }
    },
    async getFolderImages(folder) {
      try {
        await this.GET_FOLDER_IMAGES(folder);
      } catch (error) {
        console.log(error);
      }
    },
    async setFolderImage(payload) {
      try {
        console.log(payload?.folder == "");
        if (payload?.folder == undefined || payload?.folder == "") {
          throw {
            title: "Ошибка",
            text: "Укажите папку",
            status: "error",
            clean: true,
          };
        }
        if (!this.image_form.fields.image.value?.lastModified) {
          throw {
            title: "Ошибка",
            text: "Загрузите изображение",
            status: "error",
            clean: true,
          };
        }
        this.SET_FOLDER_IMAGE(payload).then(async ({ data }) => {
          this.$store.state.folder_images = [];
          this.image_form.fields.image.value = {};
          this.GLOBAL_NOTIFICATIONS(data);
          await this.folderController("getFolderImages");
        });
      } catch (error) {
        this.GLOBAL_NOTIFICATIONS(error);
      }
    },
    // async selectImage(img) {
    //   try {
    //     console.log(img);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async deleteFolderImage(payload) {
      try {
        if (payload?.image == "" || payload.image == undefined) {
          throw {
            title: "Ошибка",
            text: "Выберите изображение из списка",
            status: "error",
            clean: false,
          };
        }
        if (payload?.folder == "" || payload.folder == undefined) {
          throw {
            title: "Ошибка",
            text: "Выберите папку из списка",
            status: "error",
            clean: false,
          };
        }
        await this.DELETE_FOLDER_IMAGE(payload).then((res) => {
          this.GLOBAL_NOTIFICATIONS(res);
          this.folderController("getFolderImages");
        });
      } catch (error) {
        console.log(error);
      }
    },
    async closeDialog(data) {
      data.options.visible = false;
    },
  },
  computed: {
    ...mapGetters(["FOLDERS", "FOLDER_IMAGES"]),
    previewImage() {
      // return this.onFileChange(this.image_form.fields.image.value);
      return 0;
    },
    page() {
      return this.$route.name;
    },
    activeFolder() {
      return this.active_folder.value;
    },
    activeImage() {
      return this.active_image.value;
    },
    filtredImages() {
      let filtredArr = Array.from(this.FOLDER_IMAGES).filter((item) =>
        item.value
          .toLowerCase()
          .includes(this.image_form.fields.search.value.toLowerCase())
      );
      if (filtredArr.length) return filtredArr;
      else return this.FOLDER_IMAGES;
    },
  },
  async mounted() {
    // await this.GET_FOLDERS();
    // if (this.FOLDERS.length) {
    //   this.active_folder.value = this.FOLDERS?.[0]?.value;
    // }
  },
  watch: {
    "image_form.fields.image.value": {
      handler(newVal) {
        for (const key in newVal) {
          if (Object.hasOwnProperty.call(newVal, key)) {
            const element = newVal[key];
            this.onFileChange(element);
          }
        }
      },
    },
    page: {
      handler(newVal) {
        console.log(newVal);
        switch (newVal) {
          case "IngredientsViews":
            this.active_folder.value = "ingredients-icons";
            break;

          case "CategoriesViews":
            this.active_folder.value = "category-icons";
            break;

          case "BannersViews":
            this.active_folder.value = "banners";
            break;

          case "PromotionsViews":
            this.active_folder.value = "banners";
            break;

          case "ProductsViews":
            this.active_folder.value = "products";
            break;

          default:
            break;
        }
        if (this.active_folder.value?.length) {
          setTimeout(() => {
            this.folderController("getFolderImages");
          }, 500);
        }
      },
      immediate: true,
    },
  },
  setup() {
    const { user } = useAuthState();
    const auth = getAuth();
    const store = useStore();

    onMounted(async () => {
      const token = await auth.currentUser.getIdToken().then(function (token) {
        return token;
      });
      store.state.token = token;
      store.dispatch("GET_FOLDERS");
    });

    const router = useRouter();
    const signOutUser = async () => {
      try {
        await signOut(auth);
        // router.push("/auth");
        router;
      } catch (error) {
        console.log(error);
      }
    };

    return { user, signOutUser };
  },
};
</script>
<style lang="scss" scoped>
.folders__dialog-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding-bottom: 20px;
}
.folders__dialog-control {
  max-width: 32%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.folders__dialog-image {
  max-width: 175px;
  margin-top: 12px;
}
.panel {
  padding: 20px;
  padding-top: 0;
  &__title {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  // .panel__sections

  &__sections {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 24px 0;
    > a {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  // .panel__back-menu

  &__back-menu {
    margin-bottom: 20px;
  }

  // .panel__section-link

  &__section-link {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 12px;
    min-width: 90px;
    max-width: 130px;
    width: 100%;
    min-height: 90px;
    max-height: 130px;
    height: 100%;
    background-color: rgb(255, 153, 0);
    color: $white;
    font-size: 18px;
  }
}
</style>

<style lang="scss">
.folders__dialog-control {
  .BaseRadio__label {
    flex-direction: column;
    flex: 1 1 auto;
  }
}
</style>
