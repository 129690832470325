<template>
  <div class="v-accordeon">
    <div class="v-accordeon__head" @click="open">
      {{ data?.title || "Пустой заголовок" }}
      <svg
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{ active: data?.active }"
      >
        <path d="M16 0V16V32" stroke="black" stroke-width="2" />
        <path d="M32 16L16 16L5.66244e-07 16" stroke="black" stroke-width="2" />
      </svg>
    </div>
    <div class="v-accordeon__body" style="height: 0">
      <div class="v-accordeon__body-wrapper">
        {{ data?.body || "Пусто" }}
      </div>
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
export default {
  name: "v-accordeon",
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    beforeEnter(el) {
      gsap.to(el, { height: 0 });
    },
    enter(el) {
      gsap.to(el, { height: "auto" });
    },
    open() {
      this.$emit("openAccordeon", this.data);
    },
  },
  watch: {
    "data.active": {
      handler(newVal) {
        if (newVal) this.enter(this?.$el?.childNodes[1]);
        else this.beforeEnter(this?.$el?.childNodes[1]);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.v-accordeon {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  /* .v-accordeon__head */

  &__head {
    display: inherit;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: $white;
    padding: 12px;
    svg {
      transition: 0.4s ease;
      &.active {
        transform: rotate(-45deg);
      }
    }
  }

  /* .v-accordeon__body */

  &__body {
    display: inherit;
    background-color: #e7e7e7;
  }

  /* .v-accordeon__body-wrapper */

  &__body-wrapper {
    padding: 12px;
  }
}
</style>
