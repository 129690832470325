import axios from "axios";
import store from "./index";

export default {
  async function(method, url, mutations, params, payload, headers = {}) {
    return await axios({
      method,
      params,
      headers,
      data: payload,
      url: process.env.VUE_APP_API_URL + url,
    })
      .then((res) => {
        if (mutations.length)
          for (const func of mutations) {
            store.commit(func, res.data);
          }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  },
};
