import apiRequest from "../../api-request";

export default {
  ADD_TO_CART({ commit }, data) {
    commit("ADD_TO_CART", data);
    commit("SAVE_CART", data);
  },
  SET_SAVED_CART({ commit }) {
    commit("SET_SAVED_CART");
  },
  INCREMENT({ commit }, data) {
    commit("INCREMENT", data);
  },
  DECREMENT({ commit }, data) {
    commit("DECREMENT", data);
  },
  async GET_PROMOCODES() {
    return await apiRequest.function(
      "GET",
      "/promocodes",
      ["SET_PROMOCODES"],
      null
    );
  },
  async GET_ADRESSES() {
    return await apiRequest.function(
      "GET",
      "/getAddresses",
      ["SET_ADRESSES"],
      null
    );
  },
  async SEND_ORDER(
    { commit },
    { form, fd_products, fd_count, products, delivery_pay, total }
  ) {
    commit;
    let tm_payload = {},
      fd_payload = {};

    for (const key in form) {
      const el = form[key];
      tm_payload[key] = el.value;
      fd_payload[key] = el.value;
    }

    fd_payload.products = fd_products;
    fd_payload.count = fd_count;
    fd_payload.delivery_pay = delivery_pay;
    tm_payload.delivery_pay = delivery_pay;
    tm_payload.products = products;
    tm_payload.total = total;

    return await apiRequest.function("POST", "/fronthook", [], null, { fd_payload, tm_payload });
  },
};
