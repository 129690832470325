export default {
  product_name: "",
  token: null,
  server_errors: {},
  ingredients: [],
  folders: [],
  folder_images: [],
  admin_banners: [],
  adimn_categories: [],
  admin_products: [],
  admin_addresses: [],
  admin_stubs: [],
  admin_affilates: [],
};
