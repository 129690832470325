<template>
  <article
    class="card"
    @click="getProductInfo"
    :style="{
      'background-color': popular_data?.jaw_color || '#FFDEB4',
    }"
  >
    <div class="card__head">
      <div class="card__image">
        <picture>
          <img :src="popular_data?.large_image" alt="" />
        </picture>
      </div>

      <v-favorite-btn v-if="!popular_data?.favorites"></v-favorite-btn>
      <v-favorite-btn-active
        v-if="popular_data?.favorites == true"
      ></v-favorite-btn-active>
    </div>
    <div class="card__body">
      <h3 class="card__title">{{ popular_data?.name }}</h3>
      <p class="card__description" v-if="popular_data?.sec_description">
        {{ popular_data?.sec_description }}
      </p>
    </div>
    <div class="card__footer">
      <p class="card__price">
        <span
          v-if="popular_data?.second_price !== '' && popular_data?.second_price"
        >
          {{ popular_data?.second_price?.split("/")[0] }} ₽
        </span>
        {{ popular_data?.total_price }} ₽
      </p>

      <button class="card__button buy-btn">
        <v-icon name="plus-icon" class="plus-icon"></v-icon>
        <img
          class="buy-support buy-animate-icon"
          src="@/assets/media/icons/success-icon.svg"
          alt=""
        />
      </button>
    </div>
  </article>
</template>
<script>
import { mapActions } from "vuex";
import { TimelineMax } from "gsap";
export default {
  name: "v-popular-item",
  props: {
    popular_data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    ...mapActions(["GET_PRODUCT_INFO", "ADD_TO_CART", "ADD_TO_FAVORITES"]),
    async getProductInfo(e, data) {
      data = this.popular_data;
      if (e.target.classList.contains("buy-btn")) {
        await this.GET_PRODUCT_INFO(this.popular_data);
        await this.ADD_TO_CART(this.popular_data);

        let icon = e.target.childNodes[0];
        let hiddenIcon = e.target.childNodes[1];

        let tl = new TimelineMax({});

        tl.to(icon, 0.5, {
          rotate: "120deg",
          transform: "scale(0.2)",
          transformOrigin: "center",
          display: "none",
        });

        tl.to(hiddenIcon, 0.5, {
          rotate: "0deg",
          opacity: 1,
          transform: "scale(1)",
          display: "initial",
        });

        tl.play().then(() => {
          setTimeout(() => {
            tl.reverse();
          }, 1000);
        });
        this.$store.state.product = { product: "empty" };
      } else if (
        e.target.parentNode.classList.contains("favorite-btn") ||
        e.target.classList.contains("favorite-icon-path") ||
        e.target.classList.contains("favorite-icon-active") ||
        e.target.classList.contains("favorite-icon")
      ) {
        this.ADD_TO_FAVORITES(data);
      } else {
        this.GET_PRODUCT_INFO(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-btn {
}

.card {
  @include container;
  min-width: 176px;
  max-width: 176px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 9px;
  cursor: pointer;
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex: 1 1 auto;
  }
  &__image {
    font-size: 0;
    width: 100%;
    max-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
  }
  &__image * {
    width: 100%;
  }
  &__body {
    margin-bottom: -3px;
  }
  &__title {
    @include h3;
  }
  &__description {
    color: $second-black;
    font-size: 12px;
  }
  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__price {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-size: 12px;
      text-decoration: line-through;
    }
  }
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      pointer-events: none;
    }
  }
}

.buy-animate-icon {
  transform: scale(0);
  display: none;
  min-width: 28px;
  min-height: 28px;
}
@media (max-width: 416px) {
  .card {
    min-width: initial !important;
    max-width: initial;
    width: 48%;
    margin-right: 0;
    position: relative;
    &__image * {
    }
    .favorite-btn {
      position: absolute;
      top: 12px;
      right: 9px;
    }
  }
}
</style>
