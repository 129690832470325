<template>
  <transition name="fade" mode="out-in" :css="true">
    <div class="popup" v-show="data.options.visible">
      <h1 class="popup__title">
        {{ data.title }}
      </h1>
      <div class="popup__exit" @click.self="$emit('closeDialog', data)">x</div>

      <slot></slot>
      <div class="popup__controls">
        <button
          class="popup__delete popup__control"
          @click="$emit('adminDelete', 'delete', data.options.controll)"
        >
          Удалить
        </button>
        <button
          class="popup__save popup__control"
          @click="$emit('adminSave', 'save', data.options.controll)"
        >
          Сохранить
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "v-dialog",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  overflow-y: auto;
  padding: 40px;

  // .popup__title

  &__title {
    margin-bottom: 36px;
  }

  // .popup__exit

  &__exit {
    position: sticky;
    top: 0px;
    right: 0px;
    background-color: $second-black;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $white;
    z-index: 10;
    font-size: 16px;
    font-weight: 400;
    border-radius: 10px;
    min-height: 44px;
  }

  // .popup__controls

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0px;
    width: 100%;
  }

  // .popup__control

  &__control {
    width: 49%;
    padding: 14px;
    border: 1px solid $accent;
    background-color: $white;
    border-radius: 10px;
    cursor: pointer;
  }
  // .popup__save

  &__save {
    margin: 12px 0;
  }
  // .popup__delete

  &__delete {
  }
}
</style>
